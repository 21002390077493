<template>
    <div id="about-airvey-editions" class="container">
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <p>
                Attention : Cette année 2022, nous n'accepterons que les propositions de polars. Notre catalogue est complet pour tout autre genre.
            </p>
            <p>
                Pour nous contacter concernant votre projet de publication, veuillez tout d'abord nous remettre un synopsis et un résumé, ainsi qu'une courte présentation de vous-même.
            </p>
            <p>
                Si nous pensons qu'il y a adéquation avec notre ligne éditoriale, nous vous demanderons de nous communiquer votre tapuscrit pour étude et éventuelle publication.
            </p>
            <p>
                Notre délai de réponse à votre premier message ne dépassera pas une semaine. Quand à l'étude d'un tapuscrit, il peut prendre un à deux mois. La publication d'un texte accepté se fait en moyenne entre deux et six mois, en fonction de notre planning et de la période de l'année. Contactez <a href="mailto:airvey.editions@orange.fr" type="mail">airvey.editions@orange.fr</a> Merci.
            </p>
            <p>
                <span class="text-less-bold">Important</span> : Airvey éditions est un éditeur à compte d'éditeur. Les auteurs retenus ne sont pas censés acheter leurs livres pour être publiés.
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutAirveyEditions",
};
</script>

<style scoped>

</style>