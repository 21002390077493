var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"text-muted text-normal text-uppercase mb-4"},[_c('span',{staticClass:"fas fa-map-marked-alt mr-1 text-primary"}),_vm._v(" "+_vm._s(_vm.$t('shared.address.addressForm.generalInfo'))+" ")]),_c('hr',{staticClass:"margin-bottom-1x"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.name}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.name"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.name),expression:"localAddress.name"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('shared.address.addressForm.requiredName'),"disabled":_vm.disabled,"type":"text","id":_vm.HTMLIds.address.name,"required":""},domProps:{"value":(_vm.localAddress.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.country}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.country"))+" "),_c('span',[_vm._v("*")])]),_c('v-select',{attrs:{"id":_vm.HTMLIds.address.country,"clearable":false,"disabled":_vm.disabled,"value":_vm.formattedCountrySelected,"options":_vm.formattedCountries,"filter-by":_vm.filterBy},on:{"input":_vm.setSelectedCountry},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('img',{attrs:{"width":"20","alt":"flag","src":option.flagURL}}),_vm._v(" "+_vm._s(option.label)+" ")]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.street}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.address"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.street),expression:"localAddress.street"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('shared.address.addressForm.required'),"type":"text","id":_vm.HTMLIds.address.street,"disabled":_vm.disabled,"required":""},domProps:{"value":(_vm.localAddress.street)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "street", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.streetBis}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.addressBis"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.streetBis),expression:"localAddress.streetBis"}],staticClass:"form-control",class:classes,attrs:{"type":"text","disabled":_vm.disabled,"placeholder":!_vm.disabled && _vm.$t('shared.address.addressForm.optional'),"id":_vm.HTMLIds.address.streetBis},domProps:{"value":(_vm.localAddress.streetBis)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "streetBis", $event.target.value)}}})])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"rules":"zip_code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.zipCode}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.zipCode"))+" "),(!_vm.isZipCodeOptional)?_c('span',[_vm._v("*")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.zipCode),expression:"localAddress.zipCode"}],staticClass:"form-control",class:classes,attrs:{"placeholder":!_vm.isZipCodeOptional ? _vm.$t('shared.address.addressForm.required') : _vm.$t('shared.address.addressForm.optional'),"type":"text","id":_vm.HTMLIds.address.zipCode,"disabled":_vm.disabled,"required":!_vm.isZipCodeOptional},domProps:{"value":(_vm.localAddress.zipCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "zipCode", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.city}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.city"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.city),expression:"localAddress.city"}],staticClass:"form-control",class:classes,attrs:{"placeholder":_vm.$t('shared.address.addressForm.required'),"disabled":_vm.disabled,"type":"text","id":_vm.HTMLIds.address.city,"required":""},domProps:{"value":(_vm.localAddress.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "city", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"rules":"mobile_phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.mobilePhone}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.mobilePhone"))+" "),(_vm.isMobilePhoneRequiredForDelivery)?_c('span',[_vm._v("*")]):_vm._e()]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.mobilePhone),expression:"localAddress.mobilePhone"}],staticClass:"form-control",class:classes,attrs:{"disabled":_vm.disabled,"required":_vm.isMobilePhoneRequiredForDelivery,"type":"text","id":_vm.HTMLIds.address.mobilePhone},domProps:{"value":(_vm.localAddress.mobilePhone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "mobilePhone", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}])})],1),_c('div',{staticClass:"col-md-6"})]),(_vm.isUserProfessional)?_c('div',[_c('hr'),_c('h6',{staticClass:"text-muted text-normal text-uppercase padding-top-1x mb-4"},[_c('i',{staticClass:"fas fa-building text-primary mr-1"}),_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.societyInfo"))+" ")]),_c('hr',{staticClass:"mb-4"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.GLN}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.gln"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.gln),expression:"localAddress.gln"}],staticClass:"form-control",class:classes,attrs:{"placeholder":!_vm.disabled && _vm.$t('shared.address.addressForm.optional'),"disabled":_vm.disabled,"type":"text","id":_vm.HTMLIds.address.GLN},domProps:{"value":(_vm.localAddress.gln)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "gln", $event.target.value)}}})])]}}],null,false,543429376)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":_vm.HTMLIds.address.VATIN}},[_vm._v(" "+_vm._s(_vm.$t("shared.address.addressForm.vatin"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localAddress.vatin),expression:"localAddress.vatin"}],staticClass:"form-control",class:classes,attrs:{"placeholder":!_vm.disabled && _vm.$t('shared.address.addressForm.optional'),"disabled":_vm.disabled,"type":"text","id":_vm.HTMLIds.address.VATIN},domProps:{"value":(_vm.localAddress.vatin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.localAddress, "vatin", $event.target.value)}}})])]}}],null,false,690475137)})],1)])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }