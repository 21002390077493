import { stringify } from "qs";
import Axios from "axios";
import Order from "../classes/Order";
import Config from "../../config";
import {decodeJWTToken, generateQueryStringURL, generateValidQueryStrings} from "@/helpers/API";

function generateSelfPublishgQuoteProductData(book, quantity) {
    const { components, dimensions, paging, reducedTax } = book;
    const { userId } = decodeJWTToken();
    const options = book.options.length ? book.options : undefined;
    return {
        user: userId ? userId : undefined,
        products: JSON.parse(JSON.stringify([{
            components,
            dimensions,
            paging,
            quantity,
            reducedTax,
            options,
            title: "Livre auto-imprimé",
        }])),
    };
}

const HermesAPI = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        const axios = Axios.create({
            baseURL: Config.hermesAPI.baseURL,
            timeout: 0,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${options.token}`,
            },
        });

        Vue.prototype.$hermesAPI = {};

        Vue.prototype.$hermesAPI.getConfig = () => axios.get("/");

        Vue.prototype.$hermesAPI.setToken = token => {
            axios.defaults.headers["Authorization"] = `Bearer ${token}`;
        };

        Vue.prototype.$hermesAPI.generateValidQueryStrings = params => generateValidQueryStrings(params);

        Vue.prototype.$hermesAPI.getPublishers = queryStrings => axios.get(generateQueryStringURL(`/publishers`, queryStrings));

        Vue.prototype.$hermesAPI.getProducts = queryStrings => axios.get(`/products?${stringify(queryStrings)}`);

        Vue.prototype.$hermesAPI.addProduct = data => axios.post(`/products`, data);

        Vue.prototype.$hermesAPI.updateProduct = data => axios.patch(`/products/${data._id}`, data);

        Vue.prototype.$hermesAPI.getBookCollections = queryStrings => axios.get(generateQueryStringURL(`/products/book-collections`, queryStrings));

        Vue.prototype.$hermesAPI.getProduct = (id, queryStrings) => axios.get(generateQueryStringURL(`/products/${id}`, queryStrings));

        Vue.prototype.$hermesAPI.deleteProduct = id => axios.delete(`/products/${id}`);

        Vue.prototype.$hermesAPI.reviewProduct = (id, data) => axios.post(`/products/${id}/reviews`, data);

        Vue.prototype.$hermesAPI.editProductReview = (id, data) => axios.patch(`/products/${id}/reviews/${data._id}`, data);

        Vue.prototype.$hermesAPI.deleteProductReview = (productId, reviewId) => axios.delete(`/products/${productId}/reviews/${reviewId}`);

        Vue.prototype.$hermesAPI.voteProductReview = (productId, reviewId, data) => axios.post(`/products/${productId}/reviews/${reviewId}/vote`, data);

        Vue.prototype.$hermesAPI.unsetVoteProductReview = (productId, reviewId, data) => axios.delete(`/products/${productId}/reviews/${reviewId}/vote`, { data });

        Vue.prototype.$hermesAPI.register = user => axios.post(`/users`, user);

        Vue.prototype.$hermesAPI.login = user => axios.post(`/users/login`, user);

        Vue.prototype.$hermesAPI.activateAccount = token => axios.patch(`/users/activate-account`, { token });

        Vue.prototype.$hermesAPI.resetPasswordRequest = email => axios.post(`/users/reset-password-request`, { email });

        Vue.prototype.$hermesAPI.resetPassword = (token, password) => axios.patch(`/users/reset-password`, { token, password });

        Vue.prototype.$hermesAPI.getUsers = queryStrings => axios.get(`/users?${stringify(queryStrings)}`);

        Vue.prototype.$hermesAPI.getUsersAsCSV = () => axios({
            method: "get",
            url: `users?${stringify({ "response-format": "CSV" })}`,
            responseType: "blob",
        });

        Vue.prototype.$hermesAPI.getUser = queryStrings => {
            const { userId } = decodeJWTToken();
            return axios.get(generateQueryStringURL(`/users/${userId}`, queryStrings));
        };

        Vue.prototype.$hermesAPI.updateUser = user => {
            const { userId } = decodeJWTToken();
            return axios.patch(`/users/${userId}`, user);
        };

        Vue.prototype.$hermesAPI.updatePassword = data => {
            const { userId } = decodeJWTToken();
            return axios.patch(`/users/${userId}/password`, data);
        };

        Vue.prototype.$hermesAPI.addAddress = address => {
            const { userId } = decodeJWTToken();
            return axios.post(`users/${userId}/addresses`, address);
        };

        Vue.prototype.$hermesAPI.updateAddress = address => {
            const { userId } = decodeJWTToken();
            return axios.put(`users/${userId}/addresses/${address._id}`, address);
        };

        Vue.prototype.$hermesAPI.deleteAddress = id => {
            const { userId } = decodeJWTToken();
            return axios.delete(`users/${userId}/addresses/${id}`);
        };

        Vue.prototype.$hermesAPI.getCart = queryStrings => {
            const { userId } = decodeJWTToken();
            return axios.get(generateQueryStringURL(`users/${userId}/cart`, queryStrings));
        };

        Vue.prototype.$hermesAPI.addCartProduct = (product, queryStrings) => {
            const { userId } = decodeJWTToken();
            return axios.post(generateQueryStringURL(`users/${userId}/cart/products`, queryStrings), product);
        };

        Vue.prototype.$hermesAPI.updateCartProduct = (product, queryStrings) => {
            const { userId } = decodeJWTToken();
            return axios.patch(generateQueryStringURL(`users/${userId}/cart/products/${product._id}`, queryStrings), product);
        };

        Vue.prototype.$hermesAPI.deleteCartProduct = id => {
            const { userId } = decodeJWTToken();
            return axios.delete(`users/${userId}/cart/products/${id}`);
        };

        Vue.prototype.$hermesAPI.deleteCart = () => {
            const { userId } = decodeJWTToken();
            return axios.delete(`users/${userId}/cart`);
        };

        Vue.prototype.$hermesAPI.getWithdrawalPoints = queryStrings => axios.get(generateQueryStringURL(`withdrawal-points`, queryStrings));

        Vue.prototype.$hermesAPI.getWithdrawalPoint = id => axios.get(`withdrawal-points/${id}`);

        Vue.prototype.$hermesAPI.testOrder = (order, queryStrings) => {
            const data = JSON.parse(JSON.stringify(order));
            const discountCode = data.discountCode.code;
            data.products = data.products.map(product => ({ _id: product._id, quantity: product.quantity, customization: product.customization }));
            return axios.post(generateQueryStringURL(`/orders/test-order`, queryStrings), { ...data, discountCode });
        };

        Vue.prototype.$hermesAPI.createOrder = order => {
            const data = JSON.parse(JSON.stringify(order));
            const discountCode = data.discountCode.code;
            data.products = data.products.map(product => ({ _id: product._id, quantity: product.quantity, options: product.options.filter(({ name }) => name === "dedication") }));
            return axios.post(`orders`, { ...data, discountCode });
        };

        Vue.prototype.$hermesAPI.updateOrder = order => axios.patch(`orders/${order._id}`, order);

        Vue.prototype.$hermesAPI.getOrders = queryStrings => axios.get(`/orders?${stringify(queryStrings)}`);

        Vue.prototype.$hermesAPI.getOrder = id => axios.get(`orders/${id}`);

        Vue.prototype.$hermesAPI.getOrderBilling = orderId => axios({
            method: "get",
            url: generateQueryStringURL(`orders/${orderId}`, { "response-format": "PDF" }),
            responseType: "arraybuffer",
        });

        Vue.prototype.$hermesAPI.createPayPalPayment = data => {
            if (data instanceof Order) {
                const discountCode = data.discountCode.code;
                data.products = data.products.map(product => ({ _id: product._id, quantity: product.quantity, options: product.options.filter(({ name }) => name === "dedication") }));
                return axios.post(`orders/paypal-payment`, { ...data, discountCode });
            } else {
                return axios.post(`orders/${data}/paypal-payment`);
            }
        };

        Vue.prototype.$hermesAPI.payOrder = (orderId, data) => axios.post(`orders/${orderId}/pay`, data);

        Vue.prototype.$hermesAPI.createStripePayment = data => {
            if (!data._id) {
                const discountCode = data.discountCode.code;
                data.products = data.products.map(product => ({ _id: product._id, quantity: product.quantity, options: product.options.filter(({ name }) => name === "dedication") }));
                return axios.post(`orders/stripe-payment`, { ...data, discountCode });
            } else {
                return axios.post(`orders/${data._id}/stripe-payment`, data);
            }
        };

        Vue.prototype.$hermesAPI.confirmStripePayment = data => axios.post(`orders/confirm-stripe-payment`, data);

        Vue.prototype.$hermesAPI.sendSelfPublishedProductFiles = (productId, data, progress) => {
            const config = {
                onUploadProgress: progressEvent => progress.percent = Math.round(progressEvent.loaded * 100 / progressEvent.total),
            };
            const formData = new FormData();
            formData.append("cover", data.cover.file);
            formData.append("corpus", data.corpus.file);
            return axios.post(`products/${productId}/files`, formData, config);
        };

        Vue.prototype.$hermesAPI.sendThumbnailsProductFiles = (productId, data, progress) => {
            const config = {
                onUploadProgress: progressEvent => progress.percent = Math.round(progressEvent.loaded * 100 / progressEvent.total),
            };
            const formData = new FormData();
            if (data.first) {
                formData.append("first", data.first);
            }
            if (data.fourth) {
                formData.append("fourth", data.fourth);
            }
            return axios.post(`products/${productId}/thumbnails`, formData, config);
        };

        Vue.prototype.$hermesAPI.getQuote = (book, quantity) => {
            const data = generateSelfPublishgQuoteProductData(book, quantity);
            return axios.get(`quotes?${stringify(data)}`);
        };

        Vue.prototype.$hermesAPI.getQuotePDF = (book, quantity) => {
            const data = generateSelfPublishgQuoteProductData(book, quantity);
            data["response-format"] = "PDF";
            return axios({
                method: "get",
                url: `quotes?${stringify(data)}`,
                responseType: "arraybuffer",
            });
        };
    },
};

export default HermesAPI;